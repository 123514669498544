import React from 'react';

import { useReactiveVar } from '@apollo/client';
import styled from '@emotion/styled';
import { Menu, Divider } from 'antd';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Avatar from 'react-avatar';

import AllAppsIcon from '@assets/customizedIconSet/Apps.svg';
import { capitalizeFirstLetter } from '@utils/commonJs/index';
// import Bell from '@assets/customizedIconSet/Bell.svg';
import { userVar } from 'src/apollo/reactiveVars';

import NotificationDrawer from '../NotificationDrawer';
import ProfileDrawer from '../ProfileDrawer';

const StyledDiv = styled.div`
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.7);
  background-color: ${(props) => props.theme.color.primary800};

  .ant-menu {
    // border-right: 0;
    width: 4.5em;
    display: flex;
    flex-direction: column;
    color: ${(props) => props.theme.color.gray700};
    background-color: ${(props) => props.theme.color.primary800};

    li svg {
      fill: currentColor;
      stroke-width: 0;
      height: 1em;
      width: 1em;
      transform: scale(1.8);
    }
  }

  li.ant-menu-item.menuitem {
    margin: 0;
    padding: 1rem;
    border-left: 0.25em solid transparent;
    border-right: 0.25em solid transparent;
    height: 4.5em;
    color: ${(props) => props.theme.color.gray700};
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      display: none !important;
    }
    &:not(.ant-menu-item-selected) {
      &:hover {
        background-color: ${(props) => props.theme.color.primary400};
        color: ${(props) => props.theme.color.gray500};
      }
    }
  }
  li.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child.menuitem.menuitem-collapsed {
    background-color: ${(props) => props.theme.color.primary100};
    border-left: 0.25em solid ${(props) => props.theme.color.accent700};
    color: ${(props) => props.theme.color.gray200};
  }

  .apps-collapsed-btn {
    background-color: ${(props) => props.theme.color.primary800};
    color: ${(props) => props.theme.color.gray700};
    border: none;
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.2em 1em;
    width: 100%;
    cursor: pointer;
    height: 4em;
    &:first-of-type {
      height: 4.5em;
    }
  }
  .bottom {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .apps-icon {
      margin-bottom: 1rem;
      cursor: pointer;
    }
  }
  .apps-expanded-btn {
    background-color: ${(props) => props.theme.color.primary800};
    color: ${(props) => props.theme.color.gray700};
    border: none;
    margin-top: auto;
    display: flex;
    align-items: center;
    padding: 1em;
    width: 100%;
    cursor: pointer;

    span {
      margin-left: 0.5em;
    }
  }
  .collapsed-btn {
    background-color: ${(props) => props.theme.color.primary800};
    color: ${(props) => props.theme.color.gray700};
    border: none;
    // margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em;
    width: 100%;
    cursor: pointer;
  }
  .expanded-btn {
    background-color: ${(props) => props.theme.color.primary800};
    color: ${(props) => props.theme.color.gray700};
    border: none;
    // margin-top: auto;
    display: flex;
    align-items: center;
    padding: 1em 1.5em;
    width: 100%;
    cursor: pointer;

    span {
      margin-left: 0.5em;
    }
  }
  .divider-container {
    width: 100%;
    padding: 0.1em 0.3em;
  }
  .divider-container .ant-divider-horizontal {
    margin: 0;
    // background: ${(props) => props.theme.color.gray700};
    background: ${(props) => props.theme.color.gray900};
  }
`;

const StyledLink = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
  width: 100%;
  height: 4.5em;
  cursor: default;

  svg {
    color: ${(props) => props.theme.sidebar.appIcon};
    width: 1.3em !important;
    height: 1.3em !important;
  }
`;

const StyledIconLink = styled(StyledLink)`
  svg {
    width: 1.5rem !important;
    height: auto !important;
    fill: ${(props) => props.theme.sidebar.appIcon};
  }
`;

// const StyledBell = styled(Bell)`
//   fill: ${(props) => props.theme.color.gray300};
// `;

const StyledAllAppsIcon = styled(AllAppsIcon)`
  height: 1.6em;
  width: 1.6em;
  fill: ${(props) => props.theme.color.gray700};
`;

interface field {
  icon: JSX.Element;
  title: string;
  link?: string;
  onClick(): void;
}
interface SidebarProps {
  logo: JSX.Element;
  appIcon?: JSX.Element;
  buttons: field[];
}

function Sidebar(props: {
  sidebarProps: SidebarProps;
  className?: string;
}): JSX.Element {
  // Get User
  const user = useReactiveVar(userVar);

  const router = useRouter();
  const currentTab: string = router.query.tab
    ? capitalizeFirstLetter(`${router.query.tab}`)
    : 'Home';

  // profile drawer
  const [isProfileDrawerVisible, setIsProfileDrawerVisible] =
    React.useState(false);
  const showProfileDrawer = () => {
    setIsProfileDrawerVisible(true);
  };
  const profileDrawerClose = () => {
    setIsProfileDrawerVisible(false);
  };

  // notifications drawer
  const [isNotificationsDrawerVisible, setIsNotificationsDrawerVisible] =
    React.useState(false);
  // const showNotificationsDrawer = () => {
  //   setIsNotificationsDrawerVisible(true);
  // };
  const notificationsDrawerClose = () => {
    setIsNotificationsDrawerVisible(false);
  };

  return (
    <StyledDiv>
      <Menu
        defaultSelectedKeys={[currentTab]}
        mode={'inline'}
        theme="dark"
        inlineCollapsed={true}
        inlineIndent={0}
      >
        <StyledLink>{props.sidebarProps.logo}</StyledLink>
        {props.sidebarProps.appIcon && (
          <StyledIconLink>{props.sidebarProps.appIcon}</StyledIconLink>
        )}
        {props.sidebarProps.buttons.map((field: any) => (
          <Menu.Item
            onClick={field.onClick}
            className={`menuitem menuitem-collapsed`}
            key={field.title}
            icon={field.icon}
          ></Menu.Item>
        ))}
      </Menu>
      <div className="bottom">
        <Link href="/" passHref shallow>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a>
            <StyledAllAppsIcon className="apps-icon" />
          </a>
        </Link>

        <div className={'divider-container'}>
          <Divider className={'divider'} />
        </div>

        {/* <button
          className="apps-collapsed-btn"
          onClick={() => {
            showNotificationsDrawer();
            console.log('preferences clicked');
          }}
          onKeyDown={() => showNotificationsDrawer()}
        >
          <StyledBell className="apps-icon" />
        </button> */}
        <button
          className="apps-collapsed-btn"
          onClick={() => showProfileDrawer()}
        >
          <div className={'avatar'}>
            <Avatar
              name={user?.name}
              size="2.2em"
              textSizeRatio={2.2}
              round={true}
              maxInitials={2}
            />
          </div>
        </button>
      </div>
      <NotificationDrawer
        isNotificationsDrawerVisible={isNotificationsDrawerVisible}
        notificationsDrawerClose={notificationsDrawerClose}
        closable={false}
      />
      <ProfileDrawer
        isProfileDrawerVisible={isProfileDrawerVisible}
        profileDrawerClose={profileDrawerClose}
      />
    </StyledDiv>
  );
}

export default Sidebar;
