function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const isTextOverflowing = (ref: any): boolean => {
  if (ref && ref.current) {
    const inputField = ref.current;
    return inputField.clientWidth < inputField.scrollWidth;
  }
  return false;
};

export { capitalizeFirstLetter, isTextOverflowing };
